<template>
  <div class="account-login-contaienr">
    <div class="account-login-bd">
      <div class="account-login-mainer">
        <span class="title"></span>
        <div class="account-login-switch">
          <div
            :class="['switch-item', { active: !loginType }]"
            @click="switchLogin"
          >
            验证码登录
          </div>
          <div
            :class="['switch-item', { active: loginType }]"
            @click="switchLogin"
          >
            密码登录
          </div>
        </div>
        <div class="login-form-container">
          <div class="login-form-phone" v-show="!loginType">
            <el-form
              :model="smsFrom"
              status-icon
              :rules="smsRules"
              ref="smsFrom"
            >
              <el-form-item prop="phone">
                <el-input
                  type="number"
                  placeholder="输入手机号"
                  v-model="smsFrom.phone"
                  autocomplete="off"
                >
                  <!-- 接口暂时不支持 之后接口兼容之后打开注释 -->
                  <!-- <el-select
                    slot="prepend"
                    v-model="countryCode"
                    style="width: 76px"
                    class="country-code"
                  >
                    <el-option
                      v-for="(item, i) in geo"
                      :key="i"
                      :label="item.value"
                      :value="item.value"
                    >
                      <span style="float: left; font-size: 13px">{{
                        item.name
                      }}</span>
                      <span
                        style="
                          float: right;
                          margin-left: 50px;
                          color: #8492a6;
                          font-size: 13px;
                        "
                        >{{ item.value }}</span
                      >
                    </el-option>
                  </el-select> -->
                </el-input>
              </el-form-item>
              <el-form-item prop="code">
                <div class="flex-cell">
                  <el-input
                    class="flex-cell-bd"
                    type="number"
                    placeholder="请输入验证码"
                    v-model="smsFrom.code"
                    autocomplete="off"
                    prefix-icon="el-icon-mobile"
                    :clearable="false"
                  ></el-input>
                  <el-button
                    type="text"
                    :disabled="disabled"
                    class="flex-cell-ft verify-code-btn"
                    @click="handleSendCode"
                    >{{ buttonName }}</el-button
                  >
                </div>
              </el-form-item>
              <el-form-item prop="checked">
                <div class="flex-cell">
                  <el-checkbox class="flex-cell-bd" v-model="smsFrom.checked"
                    >自动登录</el-checkbox
                  >
                  <span class="flex-cell-ft notAccount" @click="toRegister"
                    >注册账号</span
                  >
                </div>
              </el-form-item>
              <el-form-item>
                <el-button
                  :btnLoading="btnLoading"
                  :disabled="btnLoading"
                  type="primary"
                  class="login-btn"
                  @click="submitForm('smsFrom')"
                  >{{ btnLoading ? "登录中" : "登录" }}</el-button
                >
              </el-form-item>
              <!-- <el-form-item>
                <div class="flex-cell">
                  <span class="flex-cell-bd"></span>
                  <router-link
                    class="flex-cell-ft link"
                    type="primary"
                    :to="{ name: 'AccountRegister' }"
                    >注册账号</router-link
                  >
                </div>
              </el-form-item> -->
            </el-form>
            <div v-if="showWxLogin" class="login-container_o">
              <!-- <el-button type="text" @click="popQrcode">
                <i class="weixin-icon el-icon--left"></i>微信登录
              </el-button> -->
              <div class="login-text_o">其他登录方式：</div>
              <div class="login-icon-content">
                <div class="login-icon-item">
                  <img
                    class="login-icon-item_img"
                    src="@/assets/images/weixin_login.svg"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="login-form-userName">
            <el-form
              class="login-info"
              :model="passwordFrom"
              :rules="passwordRules"
              ref="passwordFrom"
              status-icon
              v-show="loginType"
            >
              <el-form-item prop="username">
                <el-input
                  v-model="passwordFrom.username"
                  placeholder="请输入用户名"
                  autocomplete="off"
                ></el-input>
              </el-form-item>
              <el-form-item
                prop="password"
                :show-message="false"
                style="margin-bottom: 22px"
              >
                <el-input
                  v-model="passwordFrom.password"
                  type="password"
                  placeholder="请输入密码"
                  autocomplete="off"
                ></el-input>
              </el-form-item>
              <el-form-item>
                <el-button
                  class="login-btn"
                  type="primary"
                  @click="submitForm('passwordFrom')"
                  :btnLoading="btnLoading"
                  :disabled="btnLoading"
                  >{{ btnLoading ? "登录中" : "登录" }}</el-button
                >
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
    </div>

    <el-dialog
      :visible.sync="qrcodeVisible"
      :before-close="handleQrcodeClose"
      custom-class="qrcode-dialog"
      top="230px"
      center
    >
      <div class="qrcode-content">
        <div id="qrcode" class="qrcode-left"></div>
        <div class="qrcode-right">
          <span class="qrcode-tip-logo"></span>
          <div class="qrcode-tip-session">
            <span class="qrcode-tip-title">微信登录</span>
            <div class="qrcode-tip-msg">
              <span>请使用微信</span>
              <span>扫描二维码登录</span>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getQuery } from "@/utils/tool";
import geo from "@/utils/geo";
import Cookie from "js-cookie";
import authService from "@/global/service/auth";
export default {
  data() {
    return {
      defaultReferer: "test" + process.env.VUE_APP_DOMAIN + "/user",
      referer: getQuery("referer"),
      btnLoading: false,
      loginType: false,
      qrcodeVisible: false,
      countryCode: "+86",
      geo: geo,
      showWxLogin: false,
      passwordFrom: {
        username: "",
        password: "",
        checked: true,
      },
      smsFrom: {
        phone: "",
        code: "",
        checked: true,
      },
      smsRules: {
        phone: [
          {
            required: true,
            message: "请输入手机号",
            trigger: "blur",
          },
          {
            pattern: /^\d+$/,
            message: "手机号只能为数字",
            trigger: "blur",
          },
        ],
        code: [
          {
            required: true,
            message: "请输入验证码",
            trigger: "blur",
          },
        ],
      },
      passwordRules: {
        username: [
          {
            required: true,
            message: "请输入用户名",
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message: "请输入密码",
            trigger: "blur",
          },
        ],
      },
      buttonName: "获取验证码",
      disabled: false,
    };
  },
  mounted() {
    if (this.checkPC()) {
      this.showWxLogin = true;
    }
  },
  methods: {
    checkPC() {
      var userAgentInfo = navigator.userAgent;
      var Agents = [
        "Android",
        "iPhone",
        "SymbianOS",
        "Windows Phone",
        "iPad",
        "iPod",
      ];
      var flag = true;
      for (var v = 0; v < Agents.length; v++) {
        if (userAgentInfo.indexOf(Agents[v]) > 0) {
          flag = false;
          break;
        }
      }
      return flag;
    },
    // withCredentials: true,
    submitForm(formName) {
      // const referer = getQuery("referer");
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (formName === "smsFrom") {
            this.btnLoading = true;
            const params = {
              phone_number: this.smsFrom.phone,
              code: this.smsFrom.code,
              // remember: this.smsFrom.checked ? 1 : 0,
            };
            authService
              .phoneLogin(params, { withCredentials: true })
              .then(() => {
                if (typeof collectEvent == "function") {
                  window.collectEvent("loginSucceed", {
                    uuid: Cookie.get("uuid"),
                    name: Cookie.get("uname"),
                    phone: this.smsFrom.phone,
                    referrer: this.referer || "",
                  });
                }
                if (this.referer) {
                  window.location.href = this.referer;
                } else {
                  this.$router.push({
                    name: "JkxyUserCenter",
                  });
                }
              })
              .catch(() => {
                this.btnLoading = false;
              });
          } else if (formName === "passwordFrom") {
            this.btnLoading = true;
            const params = {
              username: this.passwordFrom.username,
              password: this.passwordFrom.password,
              type: "username",
            };
            authService
              .passwordLogin(params, { withCredentials: true })
              .then(() => {
                if (typeof collectEvent == "function") {
                  window.collectEvent("loginSucceed", {
                    uuid: Cookie.get("uuid"),
                    name: Cookie.get("uname"),
                    referrer: this.referer || "",
                  });
                }
                if (this.referer) {
                  window.location.href = this.referer;
                } else {
                  this.$router.push({
                    name: "JkxyUserCenter",
                  });
                }
              })
              .catch(() => {
                this.btnLoading = false;
              });
          }
        }
      });
    },
    handleSendCode() {
      if (this.disabled) {
        return;
      }

      this.$refs.smsFrom.validateField("phone", (errMsg) => {
        if (errMsg) return;
        this.disabled = true;
        authService
          .phoneSms({
            phone_number: this.smsFrom.phone,
          })
          .then((res) => {
            this.countDown();
            if (process.env.VUE_APP_AUTO_CODE === "true")
              this.smsFrom.code = res.code;
          })
          .catch(() => {
            this.disabled = false;
          });
      });
    },
    countDown() {
      let time = 60;
      this.buttonName = `(${time})秒重新发送`;
      const interval = window.setInterval(() => {
        time -= 1;
        this.buttonName = `(${time})秒重新发送`;
        if (time <= 0) {
          this.buttonName = "重新发送";
          this.disabled = false;
          window.clearInterval(interval);
        }
      }, 1000);
    },

    popQrcode() {
      this.qrcodeVisible = true;

      //href的值是由qrcode.css转码而来
      // axios
      //   .get(
      //     API.wechatInfo,
      //     {
      //       scene: "login",
      //       referer: this.referer ? this.referer : this.defaultReferer,
      //     },
      //     { withCredentials: true }
      //   )
      //   .then((res) => {
      //     this.$nextTick(function () {
      //       new window.WxLogin({
      //         id: "qrcode",
      //         appid: res.appid,
      //         scope: "snsapi_login",
      //         //self_redirect:true,
      //         redirect_uri: res.redirect_uri,
      //         state: res.state,
      //         // href: "https://passport.jiker.vip/css/qrcode.css"
      //         href: "data:text/css;base64,LmltcG93ZXJCb3ggLnRpdGxlIHsKICAgIGRpc3BsYXk6IG5vbmU7Cn0KLnBhbmVsQ29udGVudCAud3JwX2NvZGUgewogICAgd2lkdGg6IDI0MHB4OwogICAgaGVpZ2h0OiAyNDBweDsKfQouaW1wb3dlckJveCAuc3RhdHVzLnN0YXR1c19icm93c2VyIHsKICAgIGRpc3BsYXk6IG5vbmU7Cn0KLmltcG93ZXJCb3ggLnN0YXR1c19pY29uIHsKICAgIG1hcmdpbi10b3A6IDEwcHg7Cn0KLmltcG93ZXJCb3ggLnFyY29kZSB7CiAgICAvKmJvcmRlcjpub25lOyovCiAgICBwYWRkaW5nOiAzMHB4OwogICAgd2lkdGg6IDIyMHB4OwogICAgaGVpZ2h0OiAyMjBweDsKfQouaW1wb3dlckJveCAuanNfd3hfZGVmYXVsdF90aXB7CiAgICBkaXNwbGF5OiBub25lOwp9Ci5pbXBvd2VyQm94ICN3eF9hZnRlcl9zY2FuLCAjd3hfYWZ0ZXJfY2FuY2VsLCN3eF9kZWZhdWx0X3RpcHsKICAgIHBhZGRpbmc6IDUycHggMTRweDsKICAgIHRleHQtYWxpZ246IGxlZnQ7Cn0KLmltcG93ZXJCb3ggLnN0YXR1c190eHQgewogICAgbWFyZ2luLXRvcDoxMnB4Owp9",
      //       });
      //     });
      //   });
    },
    handleQrcodeClose() {
      this.qrcodeVisible = false;
    },
    switchLogin() {
      if (this.loginType) {
        this.$refs["smsFrom"].resetFields();
        this.loginType = false;
      } else {
        this.$refs["passwordFrom"].resetFields();
        this.loginType = true;
      }
    },
    userLogin() {},
    toRegister() {
      this.$router.push({
        name: "JkxyRegister",
      });
    },
  },
  computed: {},
};
</script>

<style lang="less">
.weixin-icon {
  display: inline-block;
  width: 27px;
  height: 22px;
  background-image: url("~@/assets/images/weixin-icon.svg");
}
.el-input-group__append,
.el-input-group__prepend {
  background-color: #ffffff;
}
.el-input__inner,
.el-input-group__prepend,
.el-button {
  border-radius: 2px;
}
.el-checkbox {
  color: #999;
}
.el-checkbox__input.is-focus .el-checkbox__inner {
  border: 1px solid rgba(221, 221, 221, 1);
}
.el-checkbox__input.is-checked .el-checkbox__inner {
  background-color: #0fc700;
  border-color: #0fc700;
}
.el-checkbox__input.is-checked + .el-checkbox__label {
  color: #333;
  font-weight: 500;
}
.el-input__inner:focus {
  border-color: #0fc700;
}
.qrcode-dialog {
  width: 600px;
  height: 420px;
  color: #333;
  .el-dialog__header {
    padding: 9px 20px 10px;
  }
  .qrcode-content {
    position: relative;
    display: flex;
    padding: 0 35px;
    .qrcode-left {
      width: 240px;
      height: 240px;
    }
  }
  .qrcode-right {
    position: absolute;
    right: 40px;
    top: 50px;
  }
  .qrcode-tip-logo {
    background-image: url("~@/assets/images/logo.svg");
    width: 135px;
    height: 60px;
    display: inline-block;
    margin-bottom: 44px;
  }
  .qrcode-tip-title {
    display: inline-block;
    font-size: 26px;
    margin-bottom: 10px;
    line-height: 37px;
    color: #333;
    width: 108px;
  }
  .qrcode-tip-msg {
    position: relative;
    padding-left: 42px;
    display: flex;
    flex-direction: column;
    font-size: 16px;
    color: #333;
    line-height: 26px;
    &::before {
      position: absolute;
      left: 0;
      content: "";
      display: inline-block;
      width: 32px;
      height: 45px;
      background-image: url("~@/assets/images/phone.svg");
    }
  }
}
</style>
<style lang="less" scoped>
.flex-cell {
  display: flex;

  .flex-cell-bd {
    flex: 1;
  }

  .flex-cell-ft {
    &:after {
      display: none;
    }

    &.link {
      font-size: 14px;
      font-weight: 500;
      text-align: center;
      color: #409eff;
      text-decoration: none;
    }
    &.notAccount {
      color: #0fc700;
      font-weight: 600;
      cursor: pointer;
      transition: 0.2s all ease;
      &:hover {
        opacity: 0.7;
      }
    }
  }
}

.account-login-contaienr {
  display: flex;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-position: center;
  padding-top: 80px;
  box-sizing: border-box;
}

.account-login-bd {
  flex: 1;
  padding-top: 32px;
}

.company-info-container {
  display: flex;
  justify-content: center;
  align-items: center;

  .company-info-logo {
    height: 60px;
    // margin-right: 20px;
  }

  .company-info-name {
    line-height: 44px;
    font-size: 33px;
    font-weight: 600;
    font-family: Avenir, Helvetica Neue, Arial, Helvetica, sans-serif;
  }
}

.company-info-desc {
  margin: 12px 0 40px;
  font-size: 14px;
  opacity: 0.5;
  text-align: center;
}

.account-login-mainer {
  width: 340px;
  margin: 0 auto;
  padding: 16px 0;
  text-align: left;
  .title {
    background-image: url("~@/assets/images/logo_login.png");
    width: 170px;
    height: 66px;
    display: inline-block;
    margin: 0px 0 18px 78px;
    background-size: 170px 66px;
    background-repeat: no-repeat;
  }
  .login-type-name {
    font-weight: 600;
    font-size: 12px;
    line-height: 17px;
    margin-bottom: 5px;
  }
  .flex-cell-ft {
    position: absolute;
    right: 0;
    padding: 0 15px;
    font-size: 12px;
    line-height: 40px;
  }
  .el-checkbox__label {
    font-size: 12px;
    position: relative;
    top: -1px;
  }
  .el-form-item {
    &:nth-child(2) {
      margin-bottom: 10px;
    }
  }
  .verify-code-btn {
    color: #0fc700;
    margin-right: 10px;
    border: none;
  }
  .login-btn {
    width: 100%;
    font-size: 16px;
    line-height: 24px;
    background-color: #0fc700;
    border-color: #0fc700;
    margin-top: -15px;
  }
  .login-container_o {
    border: 1px solid #0fc700;
    height: 48px;
    display: flex;
    align-items: center;
    padding: 0px 30px;
    .el-button {
      color: #0fc700;
      font-size: 16px;
      margin-top: -5px;
      i {
        position: relative;
        top: 4px;
        right: 10px;
      }
    }
    .login-icon-item {
      cursor: pointer;
      &:hover {
        .login-icon-item_img {
          opacity: 0.7;
        }
      }
    }
    .login-icon-item_img {
      width: 32px;
      height: 32px;
      transition: all 0.3s ease;
    }
    .login-text_o {
      font-size: 12px;
      color: #666;
      width: 100px;
      text-align: right;
      margin-right: 12px;
    }
  }
}

.login-tab-container {
  text-align: center;
  margin-bottom: 24px;

  .login-tab-item {
    display: inline-block;
    position: relative;
    box-sizing: border-box;
    margin-right: 32px;
    padding: 12px 16px;
    cursor: pointer;
    font-size: 14px;
    line-height: 20px;
    color: #666;
    border-bottom: 2px solid transparent;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

    &:last-child {
      margin-right: 0;
    }

    &.active {
      color: #35b558;
      font-weight: 500;
      border-color: #35b558;
    }
  }
}

/deep/ .el-input__icon,
/deep/ .el-input__prefix {
  transition: none !important;
}

/deep/ .country-code {
  .el-input__validateIcon {
    display: none !important;
  }
}
.account-login-switch {
  height: 35px;
  margin-bottom: 15px;
  text-align: center;
}
.switch-item {
  font-size: 14px;
  display: inline-block;
  padding-bottom: 6px;
  margin: 0 20px;
  border-bottom: 4px solid transparent;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  cursor: pointer;
  &.active {
    font-size: 18px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #333;
    border-color: #35b558;
  }
}
</style>
